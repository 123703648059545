import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import './policy.scss'

const Policy = () => (
  <Layout>
    <SEO title="個人情報保護方針" />
    <div className='policy'>
      <section>
        <h1>個人情報保護方針</h1>
        <p>あかね(以下当社)は、当社の提供するサービス(以下本サービス)におけるユーザについての個人情報を含む利用者情報の取り扱いについて、以下のとおりプライバシーポリシー(以下本ポリシー)を定めます。</p>

        <h2>1. 収集する利用者情報および収集方法</h2>
        <p>
          本ポリシーにおいて、「利用者情報」とはユーザの識別に係わる情報、通信サービス上の行動履歴、その他ユーザまたはユーザの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
          本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
        </p>
        <ol>
          <li>
            <label>ユーザからご提供いただく情報</label>
            <p>本サービスを利用するために、または本サービスの利用を通じてユーザからご提供いただく情報は以下のとおりです。</p>
            <ul>
              <li>入力フォームその他当社が定める方法を通じてユーザが入力または送信する情報</li>
            </ul>
          </li>
          {/* <li>
            <label>ユーザが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報</label>
            <p>ユーザが本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。</p>
            <ul>
              <li>当該外部サービスでユーザが利用するID</li>
              <li>その他当該外部サービスのプライバシー設定によりユーザが連携先に開示を認めた情報</li>
            </ul>
          </li> */}
          <li>
            <label>ユーザが本サービスを利用するにあたって、当社が収集する情報</label>
            <p>当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。</p>
            <ul>
              <li>リファラ</li>
              <li>IPアドレス</li>
              <li>サーバアクセスログに関する情報</li>
              <li>Cookie、ADID、IDFAその他の識別子</li>
            </ul>
          </li>
        </ol>

        <h2>2. 利用目的</h2>
        <p>本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。</p>
        <ul>
          <li>本サービスに関する登録の受付、ユーザ認証、ユーザ設定の記録等本サービスの提供、維持、保護および改善のため</li>
          <li>ユーザのトラフィック測定および行動測定のため</li>
          <li>本サービスに関するご案内、お問い合わせ等への対応のため</li>
          <li>本サービスに関する当社の規約、ポリシー等(以下、規約等)に違反する行為に対する対応のため</li>
          <li>本サービスに関する規約等の変更などを通知するため</li>
        </ul>

        <h2>3. 通知・公表または同意取得の方法、利用中止要請の方法</h2>
        <p>
          ユーザは本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集または利用の停止を求めることができ、この場合、当社は速やかに当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集または利用を停止します。
        </p>

        <h2>4. 外部送信、第三者提供、情報収集モジュールの有無</h2>
        <ol>
          <li>本サービスでは、以下の提携先がユーザの端末にCookieを保存し、これを利用して利用者情報を蓄積および利用している場合があります。
            <ul>
              <li>Googleアナリティクス</li>
            </ul>
          </li>
          <li>本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者(日本国外にあるものを含みます)への利用者情報の提供を行います。
            <ul>
              <li>
                <label>Googleアナリティクス</label>
                <a href='https://policies.google.com/technologies/partner-sites?hl=ja' target='_blank' className='link-block' rel='noopener noreferrer'>Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用</a>
              </li>
            </ul>
          </li>
        </ol>

        <h2>5. 第三者提供</h2>
        <p>当社は、利用者情報のうち個人情報についてはあらかじめユーザの同意を得ないで、第三者(日本国外にあるものを含みます)に提供しません。ただし、次に掲げる必要があり第三者(日本国外にあるものを含みます)に提供する場合はこの限りではありません。</p>
        <ol>
          <li>当社が利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託する場合</li>
          <li>合併その他の事由による事業の継承に伴って個人情報が提供される場合</li>
          <li>第4項の定めに従って、提携先または情報収集モジュール提供者への個人情報が提供される場合</li>
          <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
          <li>その他、個人情報の保護に関する法律(以下、個人情報保護法といいます)その他の法令で認められる場合</li>
        </ol>

        <h2>6. 個人情報の開示</h2>
        <p>当社は、ユーザから個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザご本人からのご請求であることを確認の上で、ユーザに対し遅滞なく開示を行います(当該個人情報が存在しないときにはその旨を通知)。ただし、個人情報保護法その他の法令により、当社が開示の義務を追わない場合は、この限りではありません。なお、個人情報の開示につきましては手数料を別途頂戴しておりますのであらかじめご了承ください。</p>

        <h2>7. 個人情報の訂正および利用停止等</h2>
        <ol>
          <li>当社は、ユーザから(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、および(2)あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザ本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザに対しその旨を通知いたします。</li>
          <li>当社は、ユーザからユーザの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザご本人からのご請求であることを確認した上で、個人情報の消去を行い、その旨をユーザに通知します。</li>
          <li>個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、8-1および8-2の規定は適用されません。</li>
        </ol>

        <h2>8. お問い合わせ窓口</h2>
        <p>ご意見、ご質問、苦情のお申し出その他利用者情報の取り扱いに関するお問い合わせは、まずは当社<a href='https://twitter.com/akanewz' target='_blank' rel='noopener noreferrer'>Twitterアカウント</a>までお問い合わせください。</p>

        <h2>9. プライバシーポリシーの変更手続き</h2>
        <p>当社は、必要に応じて、本ポリシーを変更します。ただし、法令上ユーザの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザに対してのみ適用されるものとします。なお、当社は本ポリシーを変更する場合には、変更後の本ポリシーの施行時期および内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザに通知します。</p>

        <div className='update'>
          <ol>
            <li>2019年11月18日制定</li>
          </ol>
        </div>

      </section>
    </div>
  </Layout>
)

export default Policy
